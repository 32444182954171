import CheckoutServices from '@/services/Checkout'

export const state = () => ({
  pageTakeInStore: false,
  enableShipping: true,
  scheduleStoreBranch: [],
  storeBranch: [],
  storeBranchPAS: [],
  shippingCourier: [],
  listPaymentMethods: [],
  installments: [],
  vouchers: [],
  listTotalPayment: 0,
  selectedBank: [],
  selectedAddress: '',
  branchSelected: 0,
  orderPayload: {},
  listItem: {},
  paymentDetail: {},
  selectedCourier: {},
  noteShipping: '',
  onCheck: false,
  usePoint: false,
  isLoading: false
})

export const mutations = {
  setTakeInStore (state, payload) {
    state.pageTakeInStore = payload
  },
  setEnableShipping (state, payload) {
    state.enableShipping = payload
  },
  setScheduleStoreBranch (state, payload) {
    state.scheduleStoreBranch = payload
  },
  setStoreBranch (state, payload) {
    state.storeBranch = payload
    state.branchSelected = 0
  },
  setStoreBranchPAS (state, payload) {
    state.storeBranchPAS = payload
    if (state.pageTakeInStore) {
      state.branchSelected = 0
    }
  },
  setSelectedBranch (state, payload) {
    state.branchSelected = payload
  },
  setUnshiftBranch (state, payload) {
    state.storeBranch.splice(state.branchSelected, 1)
    state.storeBranch.splice(0, 0, payload)
  },
  setShippingCourier (state, payload) {
    state.shippingCourier = payload
  },
  setListPaymentMethods (state, payload) {
    state.listPaymentMethods = payload
    state.listTotalPayment = payload.length
  },
  setSelectedBank (state, payload) {
    state.selectedBank = payload
  },
  setSelectedAddress (state, payload) {
    state.selectedAddress = payload
    state.branchSelected = 0
  },
  setOrderPayload (state, payload) {
    state.orderPayload = payload
  },
  setAvailibility (state, payload) {
    state.shippingCourier = payload.courier_costs
    state.listItem = payload.carts
    state.paymentDetail = payload.payment_detail
    state.installments = payload.credit_card
      ? payload.credit_card.installments
      : []
    state.vouchers = payload.vouchers
  },
  setSelectedCourier (state, payload) {
    state.selectedCourier = payload
  },
  setNoteShipping (state, payload) {
    state.noteShipping = payload
  },
  setOnCheck (state) {
    state.onCheck = !state.onCheck
  },
  setUsePoint (state, payload) {
    state.usePoint = payload
  },
  setLoading (state, payload) {
    state.isLoading = payload
  }
}

export const actions = {
  getScheduleStoreBranch ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutServices.getScheduleStoreBranch(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit(
              'setScheduleStoreBranch',
              response.data.data.store_branch_schedule
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getStoreBranch ({ commit }, payload) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      CheckoutServices.getStoreBranch(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setStoreBranch', response.data.data.store_branches)
          }
          commit('setLoading', false)
          resolve(response)
        })
        .catch((error) => {
          commit('setLoading', false)
          console.log(error)
          reject(error)
        })
    })
  },
  getStoreBranchPAS ({ commit }, payload) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      CheckoutServices.getStoreBranch(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setStoreBranchPAS', response.data.data.store_branches)
          }
          commit('setLoading', false)
          resolve(response)
        })
        .catch((error) => {
          commit('setLoading', false)
          console.log(error)
          reject(error)
        })
    })
  },
  getProductStoreBranch ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutServices.getProductStoreBranch(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductStoreBranch', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getShippingCourier ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutServices.getShippingCourier(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setShippingCourier', response.data.data.shipping_couriers)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getListPayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CheckoutServices.getListPayment(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setListPaymentMethods', response.data.data.payment_methods)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getItemAvailability ({ commit }, payload) {
    commit('setOnCheck')
    return new Promise((resolve, reject) => {
      CheckoutServices.getItemAvailability(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setAvailibility', response.data.data.checkout)
            commit(
              'setListPaymentMethods',
              response.data.data.checkout.payment_methods || []
            )
          }
          commit('setOnCheck')
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}
