import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getUser (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/users',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  editUser (auth, payload) {
    return apiClient({
      method: 'patch',
      url: '/v1/general/users',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  deleteAccount (auth, payload) {
    return apiClient({
      method: 'delete',
      url: '/v1/general/users',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  changePassword (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/users/change-password',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  toggleWishlist (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/users/add-product-wishlist',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  setFCMToken (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/users/fcm-tokens',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getNotification (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/users/notifications',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  showNotification (auth, id, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/users/notifications/' + id,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  readAllNotification (auth, payload) {
    return apiClient({
      method: 'patch',
      url: '/v1/general/users/notifications/mark-all-as-read',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getHistoryPoint (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/balance-histories',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getPayment (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/accounts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  createPayment (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/accounts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  checkValidCC (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/credit-cards/check-for-new-account-checkout',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  createTokenCC (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/credit-cards/create-token',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  deletePayment (auth, payload) {
    return apiClient({
      method: 'delete',
      url: '/v1/general/accounts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  showProfileUser (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/users/${payload.username}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
