import CategoryServices from '~/services/Category'
export const state = () => ({
  parentCategories: [],
  productCategories: [],
  productChildrenCategory: [],
  productCategoriesTree: [],
  productCategory: [],
  productSimpleCategory: {},
  isLoading: false,
  category: {}
})

export const mutations = {
  SET_PARENT_CATEGORIES (state, payload) {
    state.parentCategories = payload
  },
  SET_PRODUCT_CATEGORIES (state, payload) {
    state.productCategories = payload
  },
  SET_PRODUCT_CHILDREN_CATEGORY (state, payload) {
    state.productChildrenCategory = payload
  },
  SET_PRODUCT_CATEGORIES_TREE (state, payload) {
    state.productCategoriesTree = payload
  },
  SET_PRODUCT_CATEGORY (state, payload) {
    state.productCategory = payload
  },
  SET_PRODUCT_SIMPLE_CATEGORY (state, payload) {
    state.productSimpleCategory = payload
  },
  SET_CATEGORY (state, payload) {
    state.category = payload
  }
}

export const actions = {
  getParentCategories ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CategoryServices.getParentCategories({
        loggedIn: this.$auth.loggedIn,
        value: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : this.$cookies.get('session_id') || ''
      }, payload)
        .then((response) => {
          commit('SET_PARENT_CATEGORIES', response.data.data.categories)
          resolve(response)
        })
          .catch((error) => {
            reject(error)
          })
    })
  },
  getProductCategoriesTree ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CategoryServices.getProductCategoriesTree(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.data.message === 'success') {
            commit('SET_PRODUCT_CATEGORIES_TREE', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getSimpleCategory ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CategoryServices.getSimpleCategory(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.data.message === 'success') {
            commit('SET_PRODUCT_SIMPLE_CATEGORY', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getCategories ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CategoryServices.getCategories(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('SET_PRODUCT_CATEGORY', response.data.data.categories.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getMetaCategory ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CategoryServices.getMetaCategory(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getCategory ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CategoryServices.getCategory(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('SET_CATEGORY', response.data.data.category)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export const getters = {}
