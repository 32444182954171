import GoogleMaps from '@/services/GoogleMaps'
export const state = () => ({
  location: {},
  isLoading: false
})

export const mutations = {
  setLocation (state, payload) {
    state.location = payload
  }
}

export const actions = {
  getLocation ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GoogleMaps.getLocation(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setLocation', response.data.data.results[0])
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getOptCity ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GoogleMaps.getOptCity(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getCurrentCity ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GoogleMaps.getCurrentCity(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export const getters = {}
