import OrderService from '~/services/User/order'
export const state = () => ({
  orders: [],
  reviews: [],
  isLoading: false
})

export const mutations = {
  setOrders (state, payload) {
    state.orders = payload
  },
  setReviews (state, payload) {
    state.reviews = payload
  }
}

export const actions = {
  getOrders ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderService.getOrders(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setOrders', response.data.data.orders)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getReviews ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderService.getReviews(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setReviews', response.data.data.product_reviews)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getAllReviews ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderService.getAllReviews(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setReviews', response.data.data.reviews.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export const getters = {}
