import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getMyOrderReviews (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/users/my-rating-and-reviews',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getMyOrders (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/orders',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getDetailOrder (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/general/orders/${payload}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  addOrder (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/orders',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getOrderPaymentDetail (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/general/order-payments/${payload}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getTrackingOrder (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/rajaongkir/waybill',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getReviewSuggestions (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/reviews/suggestions',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getOrderProductDetail (auth, id) {
    return apiClient({
      method: 'get',
      url: '/v1/general/order-products/' + id,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  addReview (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/reviews',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  editStatus (auth, id, payload) {
    return apiClient({
      method: 'patch',
      url: '/v1/general/orders/' + id,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  postPaymentConfirmation (auth, id, payload) {
    return apiClient({
      method: 'patch',
      url: '/v1/general/order-payments/' + id,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  downloadInvoice (auth, id, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/orders/' + id + '/invoice/download',
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
        'Application-Key': 'Q5nGL97wRBFlgQ2WOG3XDnHhV33jFIYWdHKArumKS78YaSQA3U',
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  cancelOrder (auth, id, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/orders/' + id + '/cancellations',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  checkReorder (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/order-products/reorder/check-availability',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
