import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getFlashsale (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-sessions',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductSession (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getOngoingSession (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-sessions/ongoing',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
