import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getOrders (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/general/users/my-orders',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getReviews (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/reviews',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getAllReviews (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/reviews',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
