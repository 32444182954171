import Vue from 'vue'
import '../assets/scss/vendors/_element-ui.scss'
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/en'
import {
  Pagination,
  Dialog,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Upload,
  Progress,
  Rate,
  Steps,
  Step,
  Collapse,
  CollapseItem,
  Loading,
  MessageBox,
  Message,
  Skeleton,
  SkeletonItem,
  Autocomplete,
  DatePicker,
  Image,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from 'element-ui'

locale.use(lang)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(Tooltip)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Alert)
Vue.use(Slider)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Rate)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Autocomplete)
Vue.use(DatePicker)
Vue.use(Image)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)

Vue.use(Loading.directive)
Vue.config.lang = 'id'
Vue.prototype.$loading = Loading.service
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$message = Message
