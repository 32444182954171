export default function ({ $auth, redirect, route }) {
  if ($auth.loggedIn) {
    if (route.path.includes('/account/affiliate')) {
      if (!$auth.user.affiliate) {
        return redirect('/account/home')
      } else if ($auth.user.affiliate && $auth.user.affiliate.is_new) {
        if (route.path !== '/account/affiliate') {
          return redirect('/account/affiliate')
        }
      }
    }
  }
}
