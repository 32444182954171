import { apiClient, getHeaders } from '@/services/axiosConfig'

export default {
  getMasterBanks (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/banks',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getBankAccounts (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/general/accounts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getBankAccountsWithdrawal (auth, payload) {
    return apiClient({
      method: 'get',
      url: 'v1/general/accounts/for-affiliate-balance-withdrawal',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  createBankAccount (auth, payload) {
    return apiClient({
      method: 'put',
      url: 'v1/general/accounts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  deleteBankAccount (auth, payload) {
    return apiClient({
      method: 'delete',
      url: 'v1/general/accounts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
