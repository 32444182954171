import Content from '@/services/Content'
export const state = () => ({
  slider: [],
  parentingHubBanner: [],
  bannerProduct: [],
  bannerPromotion: [],
  bannerPromotionBrand: [],
  featuredBrand: [],
  articleParentingHub: [],
  productBrands: [],
  productCategories: [],
  productPartners: [],
  topPicks: [],
  lastSeen: [],
  popup: {},
  eventSale: {},
  promotion: [],
  littleBanner: [],
  isLoading: true,
  isLoadingCategory: true,
  searchSale: '',
  privacyPolicy: {}
})

export const mutations = {
  setSlider (state, payload) {
    state.slider = payload
  },
  setParentingHubBanner (state, payload) {
    state.parentingHubBanner = payload
  },
  setFeaturedBrands (state, payload) {
    state.featuredBrand = payload
  },
  setArticlesParentingHub (state, payload) {
    state.articleParentingHub = payload
  },
  setBannerProduct (state, payload) {
    state.bannerProduct = payload
  },
  setBannerPromotion (state, payload) {
    state.bannerPromotion = payload
  },
  setBannerPromotionBrand (state, payload) {
    state.bannerPromotionBrand = payload
  },
  setProductBrands (state, payload) {
    state.productBrands = payload
  },
  setProductCategories (state, payload) {
    state.productCategories = payload
  },
  setPartners (state, payload) {
    state.productPartners = payload
  },
  setTopPickProducts (state, payload) {
    state.topPicks = payload
  },
  setLastSeenProducts (state, payload) {
    state.lastSeen = payload
  },
  setPopup (state, payload) {
    state.popup = payload
  },
  setPromotion (state, payload) {
    state.promotion = payload
  },
  setLoading (state, payload) {
    state.isLoading = payload
  },
  setLoadingCategory (state, payload) {
    state.isLoadingCategory = payload
  },
  setEventSale (state, payload) {
    state.eventSale = payload
  },
  setSearchSale (state, payload) {
    state.searchSale = payload
  },
  setLittleBanner (state, payload) {
    state.littleBanner = payload
  },
  setPrivacyPolicy (state, payload) {
    state.privacyPolicy = payload
  }
}

export const actions = {
  getSlider ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getSlider(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200 && response.data.data.contents.length) {
            commit(
              'setSlider',
              response.data.data.contents[0].contentable.banners
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getParentingHubBanner ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getParentingHubBanner(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200 && response.data.data.contents.length) {
            commit(
              'setParentingHubBanner',
              response.data.data.contents[0].contentable.banners
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getFeatured ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getFeatured(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getArticleParentingHub ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getArticleParentingHub(payload)
        .then((response) => {
          if (response.status === 200) {
            commit('setArticlesParentingHub', response.data.data.articles)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getBannerProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getBannerProduct(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200 && response.data.data.contents.length) {
            commit(
              'setBannerProduct',
              response.data.data.contents[0].contentable.banners
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getBannerPromotion ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getBannerPromotion(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200 && response.data.data.contents.length) {
            commit(
              'setBannerPromotion',
              response.data.data.contents[0].contentable.banners
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getBannerPromotionBrand ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getBannerPromotionBrand(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200 && response.data.data.contents.length) {
            commit(
              'setBannerPromotionBrand',
              response.data.data.contents[0].contentable.banners
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductBrandsNavbar ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getProductBrandsNavbar(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductBrands', response.data.data.brands)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getProductCategoriesNavbar ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getProductCategoriesNavbar(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductCategories', response.data.data.categories)
          }
          commit('setLoadingCategory', false)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getPartnersNavbar ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getPartnersNavbar(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (payload.data.type === 'brand_partner') {
              commit('setProductBrands', response.data.data || [])
            } else {
              commit('setPartners', response.data.data || [])
            }
            commit('setLoading', false)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          commit('setLoading', false)
          reject(error)
        })
    })
  },
  getPages ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getPages(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getTopPickProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getTopPickProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200 && response.data.data.contents.length) {
            commit(
              'setTopPickProducts',
              response.data.data.contents[0].contentable.products
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getLastSeenProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getLastSeenProducts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setLastSeenProducts', response.data.data.products.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getBannerSale ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getBannerSale(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getNavbarEventSale ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getNavbarEventSale(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getOngoingEventSale ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getOngoingEventSale(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getEventSaleOngoing ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getEventSaleOngoing(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getEventSaleSections ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getEventSaleSections(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getEventSaleOtherSection ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getEventSaleOtherSection(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getEventSaleSection ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getEventSaleSection(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getPopup ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getPopup(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (!payload) {
            commit('setPopup', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getPromotion ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getPromotion(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (!payload) {
            commit('setPromotion', response.data.data.promotions.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getLittleBanner ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getLittleBanner(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (!payload) {
            commit(
              'setLittleBanner',
              response.data.data.contents[0].contentable.banners || []
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getDownloadLink () {
    return new Promise((resolve, reject) => {
      Content.getDownloadLink({
        loggedIn: this.$auth.loggedIn,
        value: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : this.$cookies.get('session_id') || ''
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getNavbarCategoriesContent () {
    return new Promise((resolve, reject) => {
      Content.getNavbarCategoriesContent({
        loggedIn: this.$auth.loggedIn,
        value: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : this.$cookies.get('session_id') || ''
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getBannerTodayPromotion () {
    return new Promise((resolve, reject) => {
      Content.getBannerTodayPromotion({
        loggedIn: this.$auth.loggedIn,
        value: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : this.$cookies.get('session_id') || ''
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getSuggestionContent ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getSuggestionContent(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getPrivacyPolicy ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Content.getPrivacyPolicy(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setPrivacyPolicy', response.data.data.page)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  }
}

export const getters = {}
