import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  acceptPrivacyPolicy (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/general/affiliates/accept-privacy-policy',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getSummaryCount (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/affiliates/summary',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getAffiliateCommission (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/affiliates/commission-histories',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getWithdrawablesAffiliate (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/affiliate-balance-withdrawals/withdrawables',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  createWithdrawalAffiliate (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/affiliate-balance-withdrawals',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getWithdrawalCheckAmount (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/affiliate-balance-withdrawals/check-amount',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCommissionSummaryCount (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/affiliates/commission-summary',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCommissionHistoryDetail (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/general/affiliates/commission-histories/${payload.id}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getAffiliateBalanceWithdrawal (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/affiliate-balance-withdrawals',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getAffiliateBalanceWithdrawalDetail (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/general/affiliate-balance-withdrawals/${payload.id}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  increaseClickAffiliateProduct (auth, payload) {
    return apiClient({
      method: 'patch',
      url: `/v1/affiliate-products/${payload.code}/increase-click`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  createAffiliateRegistration (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/affiliate-registrations',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
