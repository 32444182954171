import RegionServices from '@/services/Region'
export const state = () => ({
  provinces: [],
  cities: [],
  subdistricts: [],
  villages: [],
  isLoading: false
})

export const mutations = {
  setProvinces (state, payload) {
    state.provinces = payload
  },
  setCities (state, payload) {
    state.cities = payload
  },
  setSubdistricts (state, payload) {
    state.subdistricts = payload
  },
  setVillages (state, payload) {
    state.villages = payload
  }
}

export const actions = {
  getProvinces ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RegionServices.getProvinces(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.data.status_code === 200) {
            commit('setProvinces', response.data.data.region_provinces)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getCities ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RegionServices.getCities(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.data.status_code === 200) {
            commit('setCities', response.data.data.region_cities)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getSubdistricts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RegionServices.getSubdistricts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.data.status_code === 200) {
            commit('setSubdistricts', response.data.data.region_subdistricts)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getVillages ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RegionServices.getVillages(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.data.status_code === 200) {
            commit('setVillages', response.data.data.region_villages)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export const getters = {}
