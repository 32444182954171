import UsersServices from '@/services/User/address'
export const state = () => ({
  userAddress: [],
  selectedAddress: [],
  mainAddress: '',
  isLoading: false,
  isCreated: false,
  location: {}
})

export const getters = {
  mainAddress (state) {
    const addressMain = state.userAddress.filter((address) => {
      return address.main === true
    })[0]

    return addressMain.id
  }
}

export const mutations = {
  setUserAddress (state, payload) {
    state.userAddress = payload
  },
  setSelectedAddress (state, payload) {
    state.selectedAddress = payload
  },
  setLocation (state, payload) {
    state.location = payload
  },
  setSuccess (state, payload) {
    state.isCreated = payload
  }
}

export const actions = {
  getUserAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.getUserAddress(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setUserAddress', response.data.data.addresses.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteUserAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.deleteUserAddress(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  addUserAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.addUserAddress(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setSuccess', true)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  editUserAddress ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UsersServices.editUserAddress(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}
