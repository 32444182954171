import FlashsaleService from '@/services/Flashsale'
export const state = () => ({
  isLoading: true,
  dataFlashsales: [],
  flashsale: [],
  indexFlashsale: 0,
  activeSession: 0
})

export const mutations = {
  setLoading (state, payload) {
    state.isLoading = payload
  },
  setFlashsale (state, payload) {
    const eventSaleSessions = payload.map((session) => {
      session.sessions = session.sessions.map((s) => {
        s.event_sale_products = []
        s.meta = {
          page: 1,
          perPage: 10,
          total: 0,
          totalPage: 0
        }
        return s
      })

      return session
    })

    state.dataFlashsales = eventSaleSessions
  },
  setIndexFlashsale (state, payload) {
    state.indexFlashsale = payload
  },
  setFlashsaleActiveSession (state, payload) {
    state.activeSession = payload
  },
  setOngoingFlashsale (state, payload) {
    state.flashsale = payload
  },
  clearOngoingFlashsale (state, payload) {
    state.flashsale = []
  },
  setProductSession (state, payload) {
    if (payload.config.from) {
      const { indexActive } = payload.config
      state.dataFlashsales[indexActive] = Object.assign({
        ...state.dataFlashsales[indexActive],
        event_sale_products: payload.data.data
      })
    } else {
      state.dataFlashsales[payload.config.indexFlashsale].sessions[
        payload.config.indexActive
      ] = Object.assign({
        ...state.dataFlashsales[payload.config.indexFlashsale].sessions[
          payload.config.indexActive
        ],
        event_sale_products: payload.data
      })
    }
  },
  clearProductSession (state, payload) {
    state.dataFlashsales = []
  },
  setMoreProductSession (state, payload) {
    if (payload.config.from) {
      const tempData =
        state.dataFlashsales[payload.config.indexActive].event_sale_products
          .data
      state.dataFlashsales[payload.config.indexActive].event_sale_products =
        Object.assign({
          ...payload.data,
          data: tempData.concat(payload.data.data)
        })
    } else {
      const tempData =
        state.dataFlashsales[payload.config.indexFlashsale].sessions[
          payload.config.indexActive
        ].event_sale_products.data
      state.dataFlashsales[payload.config.indexFlashsale].sessions[
        payload.config.indexActive
      ].event_sale_products = Object.assign({
        ...payload.data,
        data: tempData.concat(payload.data.data)
      })
    }
  }
}

export const actions = {
  getFlashsale ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FlashsaleService.getFlashsale(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getProductSession ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FlashsaleService.getProductSession(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMoreProductSession ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FlashsaleService.getProductSession(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setMoreProductSession', {
              data: response.data.data.event_sale_products,
              config: payload.config
            })
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getOngoingSession ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FlashsaleService.getOngoingSession(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit(
              'setOngoingFlashsale',
              response.data.data.event_sale_sessions
            )
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export const getters = {}
