import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getProductBrands (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/brands',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductBrandNavbar (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/api/v1/product-brands/navbar-top-home',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getListBrands (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/brands',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  showBrand (auth, id) {
    return apiClient({
      method: 'get',
      url: 'v1/brands/' + id,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getListBrandsSale (auth, type, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/brands/on-' + type,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getAffiliateTopBrands (auth, payload) {
    return apiClient({
      method: 'get',
      url: `v1/users/${payload.username}/affiliate-top-brands`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getBestSellingBrandAffiliate (auth, payload) {
    return apiClient({
      method: 'get',
      url: 'v1/brands/best-selling-this-month',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
