export const state = () => ({
  brandList: [
    {
      id: 1,
      name: 'Alzipmat',
      slug: 'alzipmat',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/alzipmat/banner1.png')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/alzipmat/banner2.png')
        }
      ],
      link: [
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send/?phone=6281226260934&text&app_absent=0',
          text: 'WhatsApp'
        },
        {
          name: 'Paninti.com',
          url: 'https://paninti.com/brand/41f2f856-d7b2-41c3-bc7b-4bd0cd56ebe7',
          text: 'Paninti.com'
        },
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/alzipmat-indonesia',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/alzipmat_id',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?page=1&start=0&pickupPointCode=&cnc=&multiCategory=true&excludeProductList=false&category=&brand=&merchantSearchTerm=&sc=ALZiPmat&sort=7',
          text: 'Blibli'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCR8MFS2aRL8DcZ0Pqvtoa2w',
          text: 'Youtube'
        },
        {
          name: 'Email',
          url: 'alzipmatindonesia@gmail.com',
          text: 'Email'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?_t=8WcmKeL6dLm&_r=1',
          text: 'Tiktok Shop'
        }
      ]
    },
    {
      id: 2,
      name: 'Hugo',
      slug: 'hugo',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/hugo/banner1.png')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub/etalase/hugo-baby',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/shop/78727569/search?shopCollection=47640177',
          text: 'Shopee'
        },
        {
          name: 'Paninti.com',
          url: 'https://paninti.com/products/all?search=hugo%20baby',
          text: 'Paninti.com'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send/?phone=6282110705936&text&app_absent=0',
          text: 'WhatsApp'
        },
        {
          name: 'Email',
          url: 'hugobaby.idn@gmail.com',
          text: 'Email'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?_t=8WcmKeL6dLm&_r=1',
          text: 'Tiktok Shop'
        }
      ]
    },
    {
      id: 3,
      name: 'Attitude',
      slug: 'attitude',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/attitude/a1.png')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/attitude/a2.png')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/attitude/a3.png')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/attitude',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/attitudeindonesia',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/attitude-indonesia-official-store/ATI-60023',
          text: 'Blibli'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCLEkCO1jGK9l8JPyXLO7XoQ',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://wa.wizard.id/415c9a',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@attitudeliving_id',
          text: 'Tiktok'
        },
        {
          name: 'Email',
          url: 'https://mail.google.com/mail/u/0/?fs=1&to=attitudeidnofficial@gmail.com#inbox',
          text: 'Email'
        }
      ]
    },
    {
      id: 4,
      name: "Baby K'tan",
      slug: 'baby-ktan',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/baby-ktan/banner1.png')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/baby-ktan/banner2.png')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/baby-ktan/banner3.png')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/baby-ktan?source=universe&st=product',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/babyktanofficialind',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/baby-ktan-official-store/BAK-60023?page=1&start=0&pickupPointCode=&cnc=&multiCategory=true&excludeProductList=true',
          text: 'Blibli'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCy4P1lXYE_2TH8dEMOX6Nqg',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?phone=6281290551356&text=Halo%20Baby%20K%27tan%20I%20Would%20like%20to%20know%20more%20about%20your%20shop%20from%20home%20service%20',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@babyktanid',
          text: 'Tiktok'
        },
        {
          name: 'Email',
          url: 'https://mail.google.com/mail/u/0/?fs=1&to=babyktanindonesia@gmail.com&tf=cm',
          text: 'Email'
        }
      ]
    },
    {
      id: 5,
      name: 'Babu-Bellini',
      slug: 'babu-bellini',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/babu/b1.png')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/babu/b2.png')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/babu/b3.png')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub/etalase/bellini',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/shop/78727569/search?page=0&shopCollection=126580001',
          text: 'Shopee'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/playlist?list=PLepZamiaJDfnL1QkfCYb4FY6H-AtcTOfc',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?phone=6281226260934&text=Halo%20Admin%20Bagaimana%20Cara%20Order%20Produk%20Emily?%20',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?',
          text: 'Tiktok'
        },
        {
          name: 'Email',
          url: 'https://mail.google.com/mail/u/0/?fs=1&to=bellini.indonesia@gmail.com#inbox',
          text: 'Email'
        }
      ]
    },
    {
      id: 6,
      name: 'OONEW',
      slug: 'oonew',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/oonew/1.png')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/oonew/2.png')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/oonew',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/oonewofficialidn',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/oonew-indonesia-official-store/OOW-60021?page=1&start=0&pickupPointCode=&cnc=&multiCategory=true&excludeProductList=true',
          text: 'Blibli'
        },
        {
          name: 'Lazada',
          url: 'https://www.lazada.co.id/paninti-hub-official-shop/?spm=a2o4v.8553159.0.0.277818e9RrQ6AM&&from=wangpu&sc=KVUG&price=-&brand=OONEW&q=All-Products',
          text: 'Lazada'
        },
        {
          name: 'Bukalapak',
          url: 'https://www.bukalapak.com/paninti-hub-official',
          text: 'Bukalapak'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UC0rUlZcCPnNwt-J4VeS74yQ',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?phone=6282110705936&text=Halo%20Admin%20Bagaimana%20Cara%20Order%20OONEW?',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?',
          text: 'Tiktok'
        },
        {
          name: 'Email',
          url: 'https://mail.google.com/mail/u/0/?fs=1&to=oonewidn@gmail.com&tf=cm',
          text: 'Email'
        }
      ]
    },
    {
      id: 7,
      name: 'Emily & Bear',
      slug: 'emilyandbear',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/emily/banner1.png')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/emily/banner2.png')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/emilybearid',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/emilyandbearindonesia?smtt=9&utm_source=instagram&utm_medium=seller&utm_campaign=s344814123_SS_ID_IG00_Emily-and-Bear-Indonesia&utm_content=swipeup&deep_and_web=1',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?page=1&start=0&pickupPointCode=&cnc=&multiCategory=true&excludeProductList=false&sort=7&merchantSearchTerm=&brand=&intent=false&category=&sc=Bear',
          text: 'Blibli'
        },
        {
          name: 'Lazada',
          url: 'https://www.lazada.co.id/shop/emily-and-bear1626456039/?spm=a2o4j.searchlist.search.6.35093496qo8UMp&_keyori=ss&from=suggest_sis&sis_suggestion_click=Emily%20and%20Bear%20Flagship%20Store%2Cemily%20and%20bear&sugg=emily%20and%20bear_0_1',
          text: 'Lazada'
        },
        {
          name: 'JD.ID',
          url: 'https://www.jd.id/shop/Emily-and-Bear-Indonesia_10051805.html',
          text: 'JD.ID'
        },
        {
          name: 'Paninti.com',
          url: 'https://paninti.com/brand/abe7e590-dc1b-4bfb-b342-151e4024a3f5',
          text: 'Paninti.com'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UC4kDGjm3xU-Kuqvfx8LvKqw',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?phone=6281226260932&text=Halo%20Admin%20Bagaimana%20Cara%20Order%20Produk%20Emily?%20',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@emilyandbear_id?lang=id-ID',
          text: 'Tiktok'
        }
      ]
    },
    {
      id: 8,
      name: 'Tecnimed',
      slug: 'tecnimed',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/technimed/banner1.jpg')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/technimed/banner2.jpg')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/technimed/banner3.jpg')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/tecnimed',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/tecnimedofficialind',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/tecnimed-indonesia-official-store/TEI-38639',
          text: 'Blibli'
        },
        {
          name: 'Lazada',
          url: 'https://www.lazada.co.id/shop/tecnimed-indonesia-official',
          text: 'Lazada'
        },
        {
          name: 'Bukalapak',
          url: 'https://www.bukalapak.com/paninti-hub-official/label/baby-medical-equipment',
          text: 'Bukalapak'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCGbnQJcqexiOwxO9S1SiaEA',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?phone=6282110705936&text=Halo%20admin%20Tecnimed%20Indonesia,%20boleh%20saya%20bertanya%20?',
          text: 'WhatsApp'
        }
      ]
    },
    {
      id: 9,
      name: 'Elipse Kids',
      slug: 'elipse-kids',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/elipse/banner1.png')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/elipse/banner2.png')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/elipse/banner3.png')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub/etalase/elipse-kids',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/shop/78727569/search?shopCollection=126579652',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?excludeProductList=false&promoTab=false&sc=Baby%20Feeding%20Equipment&brand=Elipse%20Kids',
          text: 'Blibli'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCibMmGLiIe_9OA0MtyuwYxg',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?phone=6281226260932&text=Halo%2C%20saya%20ingin%20bertanya',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?_t=8WcmKeL6dLm&_r=1',
          text: 'Tiktok Shop'
        }
      ]
    },
    {
      id: 10,
      name: 'Lollaland',
      slug: 'lollaland',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/lollaland/banner1.png')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/lollaland/banner2.png')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/lollaland/banner3.png')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub/etalase/lollaland',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/shop/78727569/search?page=0&shopCollection=126579534',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?excludeProductList=false&promoTab=false&sc=Lollaland&brand=Lollacup',
          text: 'Blibli'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?phone=6281226260932&text=Halo%2C%20saya%20ingin%20bertanya',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?_t=8WcmKeL6dLm&_r=1',
          text: 'Tiktok Shop'
        }
      ]
    },
    {
      id: 11,
      name: 'Kizingo',
      slug: 'kizingo',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/kizingo/banner1.jpg')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/kizingo/banner2.jpg')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub/etalase/kizingo',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/shop/78727569/search?page=0&shopCollection=126580126',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?excludeProductList=false&promoTab=false&sc=Baby%20Feeding%20Equipment&brand=Kizingo',
          text: 'Blibli'
        }
      ]
    },
    {
      id: 12,
      name: 'Norge',
      slug: 'norge',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/norge/banner1.jpg')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/norge/banner1.png')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/norge/banner2.png')
        },
        {
          id: 4,
          url: require('@/assets/img/linktree/norge/banner3.jpg')
        }
      ],
      link: [
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send/?phone=6281235133565&text&app_absent=0',
          text: 'WhatsApp Customer Care'
        },
        {
          name: 'Paninti.com',
          url: 'https://paninti.com/brand/aded6351-8e2d-4eaf-9f31-09a2998e0c25',
          text: 'Paninti.com Gratis Ongkir'
        },
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub/etalase/norge-appliances',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/shop/78727569/search?shopCollection=126576461',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?excludeProductList=false&promoTab=false&brand=NORGE',
          text: 'Blibli'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@norgeappliances?lang=id-ID',
          text: 'Tiktok'
        }
      ]
    },
    {
      id: 13,
      name: 'Matchstick Monkey',
      slug: 'matchstick-monkey',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/matchstick-monkey/banner1.jpg')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/matchstickmonkey?source=universe&st=product',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/matchstickmonkey',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?excludeProductList=false&promoTab=false&sc=Matchstick%20Monkey',
          text: 'Blibli'
        },
        {
          name: 'Bukalapak',
          url: 'https://www.bukalapak.com/paninti-hub-official/label/baby-toys?keywords=matchstick%20monkey&from=omnisearch&from_keyword_history=false&search_source=omnisearch_brand&source=navbar',
          text: 'Bukalapak'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCGbnQJcqexiOwxO9S1SiaEA',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?phone=6281280275784&text=Halo%20admin%20bagaimana%20cara%20order%20produk%20Matchstick%20Monkey?',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?',
          text: 'Tiktok'
        },
        {
          name: 'Email',
          url: 'https://mail.google.com/mail/u/0/?fs=1&to=matchstickmonkeyid@gmail.com&tf=cm',
          text: 'Email'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?_t=8WcmKeL6dLm&_r=1',
          text: 'Tiktok Shop'
        }
      ]
    },
    {
      id: 14,
      name: 'Jeune Premier',
      slug: 'jeune-premier',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/jeune-premier/banner1.jpg')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/jeune-premier/banner2.jpg')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/jeunepremier',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/jeunepremierid',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/jeune-premier-official-store/JEP-60034',
          text: 'Blibli'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCGbnQJcqexiOwxO9S1SiaEA',
          text: 'Youtube'
        },
        {
          name: 'WhatsApp',
          url: 'https://api.whatsapp.com/send/?phone=6282213082319&text=Hi%2C+Bagaimana+cara+order+tas-nya+%3F+&app_absent=0',
          text: 'WhatsApp'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?',
          text: 'Tiktok'
        },
        {
          name: 'Email',
          url: 'https://mail.google.com/mail/u/0/?fs=1&to=jeunepremierid@gmail.com&tf=cm',
          text: 'Email'
        }
      ]
    },
    {
      id: 15,
      name: 'Babyhood',
      slug: 'babyhood',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/babyhood/banner1.jpg')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/babyhood/banner2.jpg')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/babyhood/banner3.jpg')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub/etalase/babyhood',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/shop/78727569/search?shopCollection=126579876',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?excludeProductList=false&promoTab=false&sc=Babyhood',
          text: 'Blibli'
        },
        {
          name: 'Paninti.com',
          url: 'https://paninti.com/brand/16149df4-ac92-4b0e-bbb4-4d129b191917',
          text: 'Paninti.com'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?_t=8WcmKeL6dLm&_r=1',
          text: 'Tiktok Shop'
        }
      ]
    },
    {
      id: 16,
      name: 'Zuupermum',
      slug: 'zuupermum',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/zuupermum/banner1.jpg')
        },
        {
          id: 2,
          url: require('@/assets/img/linktree/zuupermum/banner2.jpg')
        },
        {
          id: 3,
          url: require('@/assets/img/linktree/zuupermum/banner3.jpg')
        }
      ],
      link: [
        {
          name: 'Whatsapp',
          url: 'https://api.whatsapp.com/message/NZDH63CWTFUYH1',
          text: 'Whatsapp Customer Service'
        },
        {
          name: 'Paninti.com',
          url: 'https://paninti.com/brand/ad12a9df-ffd6-4edd-9e7f-e93fb867a906',
          text: 'Official Website Paninti.com'
        },
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub/etalase/zuupermum',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/shop/78727569/search?shopCollection=35379980',
          text: 'Shopee '
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025?excludeProductList=false&promoTab=false&sc=Zuupermum',
          text: 'Blibli '
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UC-UBuRPiqeORUN8MEjhgYfA',
          text: 'ZUUPERMUM Indonesia Youtube Channel'
        },

        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?',
          text: 'Tiktok [Paninti Hub]'
        },
        {
          name: 'Email',
          url: 'https://mail.google.com/mail/u/0/?fs=1&to=zuupermumindonesia@gmail.com&tf=cm',
          text: 'Email [Zuupermum]'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?_t=8WcmKeL6dLm&_r=1',
          text: 'Tiktok Shop'
        }
      ]
    },
    {
      id: 17,
      name: 'Paninti Hub',
      slug: 'panintihub',
      banner: [
        {
          id: 1,
          url: require('@/assets/img/linktree/panintihub/banner1.jpg')
        }
      ],
      link: [
        {
          name: 'Tokopedia',
          url: 'https://www.tokopedia.com/panintihub',
          text: 'Tokopedia'
        },
        {
          name: 'Shopee',
          url: 'https://shopee.co.id/panintihub',
          text: 'Shopee'
        },
        {
          name: 'Blibli',
          url: 'https://www.blibli.com/merchant/paninti-hub-official-store/PAH-60025',
          text: 'Blibli'
        },
        {
          name: 'Bukalapak',
          url: 'https://www.bukalapak.com/paninti-hub-official?from=omnisearch&from_keyword_history=false&search_source=omnisearch_brand&source=navbar&page=4',
          text: 'Bukalapak'
        },
        {
          name: 'Paninti.com',
          url: 'https://paninti.com/',
          text: 'Paninti.com'
        },
        {
          name: 'Youtube',
          url: 'https://www.youtube.com/channel/UCGbnQJcqexiOwxO9S1SiaEA',
          text: 'Youtube'
        },
        {
          name: 'Tiktok',
          url: 'https://www.tiktok.com/@panintihub?lang=id-ID&is_copy_url=1&is_from_webapp=v1',
          text: 'Tiktok'
        }
      ]
    }
  ],
  brandLinkIcon: [
    {
      id: 1,
      name: 'Tokopedia',
      img: require('@/static/img/icon/linktree/tokopedia.png')
    },
    {
      id: 2,
      name: 'Shopee',
      img: require('@/static/img/icon/linktree/shopee.png')
    },
    {
      id: 3,
      name: 'Blibli',
      img: require('@/static/img/icon/linktree/blibli.png')
    },
    {
      id: 4,
      name: 'Lazada',
      img: require('@/static/img/icon/linktree/lazada.png')
    },
    {
      id: 5,
      name: 'Bukalapak',
      img: require('@/static/img/icon/linktree/bukalapak.png')
    },
    {
      id: 6,
      name: 'Youtube',
      img: require('@/static/img/icon/linktree/youtube.png')
    },
    {
      id: 7,
      name: 'WhatsApp',
      img: require('@/static/img/icon/linktree/whatsapp.png')
    },
    {
      id: 8,
      name: 'Tiktok',
      img: require('@/static/img/icon/linktree/tiktok.png')
    },
    {
      id: 9,
      name: 'Email',
      img: require('@/static/img/icon/linktree/email.png')
    },
    {
      id: 10,
      name: 'Paninti.com',
      img: require('@/static/img/icon/linktree/paninti.png')
    },
    {
      id: 11,
      name: 'JD.ID',
      img: require('@/static/img/icon/linktree/jdid.png')
    },
    {
      id: 12,
      name: 'Event',
      img: require('@/static/img/icon/linktree/event.png')
    },
    {
      id: 13,
      name: 'Instagram',
      img: require('@/static/img/icon/linktree/icon-instagram.svg')
    },
    {
      id: 14,
      name: 'Draw',
      img: require('@/static/img/icon/linktree/draw.png')
    },
    {
      id: 15,
      name: 'Form',
      img: require('@/static/img/icon/linktree/form.png')
    }
  ]
})
