import { apiClient, getHeaders } from '@/services/axiosConfig'
// import axios from 'axios'
// const apiClient2 = axios.create({
//   baseURL: process.env.BASE_API,
//   withCredentials: false,
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json'
//   }
// })

export default {
  register (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/auth/signup',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  reqForgotPassword (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/auth/forgot-password',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  resetPassword (auth, payload) {
    return apiClient({
      method: 'patch',
      url: '/v1/auth/reset-password',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  sendOtp (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/otps/send',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  verifyOtp (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/otps/verify',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
