import Files from '@/services/Files'
export const state = () => ({
  isLoading: false,
  video: []
})

export const mutations = {
  setVideo (state, payload) {
    state.video = payload
  }
}

export const actions = {
  uploadFiles ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Files.uploadFiles(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getYoutubeVideos ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Files.getYoutubeVideos(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.type,
        payload.id
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setVideo', response.data.data.youtube_channel_videos.items)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export const getters = {}
