const state = () => ({
  isOpen: false,
  isEdit: false
})

const getters = {
  isOpen: state => state.isOpen
}

const actions = {
  openMenu ({ commit }) {
    commit('isOpen')
  }
}

const mutations = {
  isOpen: state => (state.isOpen = !state.isOpen),
  setIsEdit: state => (state.isEdit = !state.isEdit)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
