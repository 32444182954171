export default function ({ redirect, route, app, store }) {
  const isMaintenance = app.context.env.MAINTENANCE_MODE === 'true'
  if (route.path !== '/check-health') {
    if (isMaintenance) {
      if (app.$cookies.get('byPass') && !route.query.backdoor) {
        return redirect({
          path: route.path,
          query: {
            ...route.query,
            backdoor: app.context.env.BACKDOOR_KEY
          }
        })
      }
      if (route.query.backdoor !== app.context.env.BACKDOOR_KEY) {
        app.$cookies.set('byPass', false, {
          maxAge: 60 * 60 * 24
        })
        return redirect('/maintenance')
      } else {
        app.$cookies.set('byPass', true, {
          maxAge: 60 * 60 * 24
        })
      }
    }
    if (isMaintenance === false && route.path === '/maintenance') {
      return redirect('/')
    }
  }
}
