// Sidebar Store
export const state = () => ({
  mainSidebar: false,
  accSidebar: false
})

export const mutations = {
  setMainSidebar (state, payload) {
    state.mainSidebar = !state.mainSidebar
  },
  setAccSidebar (state, payload) {
    state.accSidebar = !state.accSidebar
  }
}
