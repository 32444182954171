import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  showPartner (auth, id) {
    return apiClient({
      method: 'get',
      url: 'v1/stores/' + id,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getPartners (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/stores',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getNearestPartners (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/stores/around-you',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getRelatedPartners (auth, id, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/stores/' + id + '/related-stores',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductRecommendations (auth, id, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/stores/' + id + '/recommended-products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getShowcasePartner (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/showcases/store-profile',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
