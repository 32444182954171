import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getProvinces (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/regions/provinces',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCities (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/regions/cities',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getSubdistricts (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/regions/subdistricts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getVillages (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/regions/villages',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
