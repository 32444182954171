import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  uploadFiles (auth, payload) {
    return apiClient.post('v1/general/file', payload, {
      headers: {
        ...apiClient.defaults.headers,
        'Content-Type': 'multipart/form-data',
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getYoutubeVideos (auth, type, id) {
    return apiClient({
      method: 'post',
      url: 'v1/' + type + '/' + id + '/youtube-channel-videos',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  }
}
