import BankAccountService from '@/services/BankAccount'
import { getPageNumber } from '~/utils/helper'

export const state = () => ({
  masterBanks: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loading: false
  },
  bankAccounts: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loading: false
  },
  bankAccountsWithdrawal: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loading: false
  }
})

export const mutations = {
  setMasterBanks (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.masterBanks.data.length) {
        state.masterBanks.data = data
      } else {
        const oldData = state.masterBanks.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.masterBanks.data = oldData.concat(data)
      }
    } else {
      state.masterBanks.data = data
    }

    state.masterBanks.pagination = { totalPage, total, nextPage }
    state.masterBanks.paginated = paginated
  },
  setBankAccounts (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.bankAccounts.data.length) {
        state.bankAccounts.data = data
      } else {
        const oldData = state.bankAccounts.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.bankAccounts.data = oldData.concat(data)
      }
    } else {
      state.bankAccounts.data = data
    }

    state.bankAccounts.pagination = { totalPage, total, nextPage }
    state.bankAccounts.paginated = paginated
  },
  setBankAccountsWithdrawal (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.bankAccountsWithdrawal.data.length) {
        state.bankAccountsWithdrawal.data = data
      } else {
        const oldData = state.bankAccountsWithdrawal.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.bankAccountsWithdrawal.data = oldData.concat(data)
      }
    } else {
      state.bankAccountsWithdrawal.data = data
    }

    state.bankAccountsWithdrawal.pagination = { totalPage, total, nextPage }
    state.bankAccountsWithdrawal.paginated = paginated
  }
}

export const actions = {
  getMasterBanks ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BankAccountService.getMasterBanks(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const banks = response.data.data.banks
          const paginated = payload.data.paginated
          const bankPayload = {
            paginated,
            data: paginated ? banks.data : banks,
            totalPage: paginated ? banks.last_page : 1,
            total: paginated ? banks.total : banks.length,
            nextPage: paginated ? getPageNumber(banks.next_page_url) : null
          }

          commit('setMasterBanks', bankPayload)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getBankAccounts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BankAccountService.getBankAccounts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const accounts = response.data.data.accounts
          const paginated = payload.data.paginated
          const bankPayload = {
            paginated,
            data: paginated ? accounts.data : accounts,
            totalPage: paginated ? accounts.last_page : 1,
            total: paginated ? accounts.total : accounts.length,
            nextPage: paginated ? getPageNumber(accounts.next_page_url) : null
          }

          commit('setBankAccounts', bankPayload)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getBankAccountsWithdrawal ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BankAccountService.getBankAccountsWithdrawal(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const accounts = response.data.data.accounts
          const paginated = payload.params.paginated
          const bankPayload = {
            paginated,
            data: paginated ? accounts.data : accounts,
            totalPage: paginated ? accounts.last_page : 1,
            total: paginated ? accounts.total : accounts.length,
            nextPage: paginated ? getPageNumber(accounts.next_page_url) : null
          }

          commit('setBankAccountsWithdrawal', bankPayload)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  createBankAccount ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BankAccountService.createBankAccount({
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteBankAccount ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BankAccountService.deleteBankAccount({
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
