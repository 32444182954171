import FilterServices from '@/services/Product/filter'

export const state = () => ({
  filterCategories: [],
  filterBrands: [],
  filterColor: [],
  filterSize: [],
  filterPrice: [0, 10000000],
  filterLocation: [],
  loadData: true
})

export const mutations = {
  setFilterCategories (state, payload) {
    state.filterCategories = []
    const temp = payload.length
      ? payload.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      : []
    temp.map((cat) => {
      state.filterCategories.push({
        ...cat,
        level: 2
      })
    })
  },
  setFilterBrands (state, payload) {
    state.filterBrands = payload.length
      ? payload.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      : []
  },
  setFilterColors (state, payload) {
    state.filterColor = payload.length
      ? payload.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      : []
  },
  setFilterSizes (state, payload) {
    state.filterSize = payload.length
      ? payload.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      : []
  },
  setFilterPrice (state, payload) {
    state.filterPrice = [payload.from, payload.until]
  },
  setFilterLocation (state, payload) {
    state.filterLocation = payload
  },
  setLoadData (state, payload) {
    state.loadData = payload
  }
}

export const actions = {
  getFilterCategories ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FilterServices.getFilterCategories(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setFilterCategories', response.data.data.categories.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getFilterBrands ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FilterServices.getFilterBrands(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setFilterBrands', response.data.data.brands.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getFilterVariants ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FilterServices.getFilterVariants(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (payload.data.where_has[0].where.name === 'Color') {
              commit('setFilterColors', response.data.data.variants)
            } else if (payload.data.where_has[0].where.name === 'Size') {
              commit('setFilterSizes', response.data.data.variants)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getFilterCategoryPage ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoadData', true)
      FilterServices.getFilterCategoryPage(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setFilterCategories', response.data.data.category)
            commit('setFilterBrands', response.data.data.brand)
            commit('setFilterColors', response.data.data.variant.color || [])
            commit('setFilterSizes', response.data.data.variant.size || [])
            commit('setFilterPrice', response.data.data.price)
            commit('setFilterLocation', response.data.data.location)
          }
          commit('setLoadData', false)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('setLoadData', false)
          reject(error)
        })
    })
  },
  getFilterPage ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoadData', true)
      FilterServices.getFilterPage(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setFilterCategories', response.data.data.category)
            commit('setFilterBrands', response.data.data.brand)
            commit('setFilterColors', response.data.data.variant.color || [])
            commit('setFilterSizes', response.data.data.variant.size || [])
            commit('setFilterPrice', response.data.data.price)
            commit('setFilterLocation', response.data.data.location)
          }
          commit('setLoadData', false)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          commit('setLoadData', false)
          reject(error)
        })
    })
  }
}

export const getters = {}
