import axios from 'axios'
export function getHeaders (auth, value) {
  if (auth) {
    return {
      Authorization: value
    }
  } else {
    return {
      'Session-id': value
    }
  }
}
export const apiClient = axios.create({
  baseURL: process.env.BASE_API,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Application-Key': 'Q5nGL97wRBFlgQ2WOG3XDnHhV33jFIYWdHKArumKS78YaSQA3U'
  }
})
export const apiClientParentingHub = axios.create({
  baseURL: 'https://api.parentinghub.co.id/api/paninti-store',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
    // 'Application-Key': 'Q5nGL97wRBFlgQ2WOG3XDnHhV33jFIYWdHKArumKS78YaSQA3U'
  }
})
