import {
  apiClient,
  apiClientParentingHub,
  getHeaders
} from '@/services/axiosConfig'
export default {
  getSlider (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/homepage-banner-slider',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getParentingHubBanner (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/parenting-hub-banner'
    })
  },
  getFeatured (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/' + payload,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getBannerProduct (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/homepage-product-banner',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getBannerPromotion (auth) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/banner-promotion',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getBannerPromotionBrand (auth) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/banner-brand-promotion',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getArticleParentingHub (payload) {
    return apiClientParentingHub({
      method: 'get',
      url: '/articles?paginate=true&per_page=5&latest=true',
      ...payload
    })
  },
  getProductBrandsNavbar (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/brands/navbar',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductCategoriesNavbar (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/categories/navbar',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getPartnersNavbar (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/stores/navbar',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getPages (auth, section) {
    return apiClient({
      method: 'get',
      url: '/v1/pages/' + section,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getTopPickProducts (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/product-top-picks',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getLastSeenProducts (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/products/last-seen',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getBannerSale (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/sale-banner',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getNavbarEventSale (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/event-sales/navbar',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getOngoingEventSale (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-sessions/ongoing',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getEventSaleOngoing (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-ongoing',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getEventSaleSections (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-products/by-sections',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getEventSaleOtherSection (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-products/by-sections/other-section',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getEventSaleSection (auth, id, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-products/by-sections/' + id,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getPopup (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/promotions/popups',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getPromotion (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/promotions',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getLittleBanner (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/little-banner',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getDownloadLink (auth) {
    return apiClient({
      method: 'get',
      url: '/v1/deep-links/get-mobile-app',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getNavbarCategoriesContent (auth) {
    return apiClient({
      method: 'get',
      url: '/v1/categories/navbar/content',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getBannerTodayPromotion (auth) {
    return apiClient({
      method: 'post',
      url: '/v1/contents/banner-today-promotion',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getSuggestionContent (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/catalogs/search?search=' + payload,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getPrivacyPolicy (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/pages/syarat-ketentuan-program-afiliasi',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
