export default {
  methods: {
    setCookies () {
      if (!this.$cookies.get('session_id')) {
        let randomString = ''
        const time = new Date().getTime()
        const chars =
          '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
        for (let i = 10; i > 0; --i) {
          randomString += chars[Math.floor(Math.random() * chars.length)]
        }
        this.$cookies.set('session_id', time + randomString, {
          maxAge: 60 * 60 * 24 * 30,
          path: '/'
        })
      }
    }
  }
}
