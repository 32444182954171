import Auth from '@/services/Auth'
export const state = () => ({
  isLoading: false
})

export const mutations = {}

export const actions = {
  register ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Auth.register(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  reqForgotPassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Auth.reqForgotPassword(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  resetPassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Auth.resetPassword(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  sendOtp ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Auth.sendOtp(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verifyOtp ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Auth.verifyOtp(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export const getters = {}
