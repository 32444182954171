export const appEnv = {
  production: 'production',
  staging: 'staging',
  development: 'development',
  local: 'local'
}

export const getPageNumber = (page) => {
  if (page) {
    return Number(page.substring(page.lastIndexOf('=') + 1, page.length))
  }
  return null
}

export const filterObjectKeys = (obj, excluded) => {
  const filteredKeys = Object.keys(obj).filter(key => !excluded.includes(key))
  const filteredObject = {}
  filteredKeys.forEach((key) => {
    filteredObject[key] = obj[key]
  })
  return filteredObject
}
