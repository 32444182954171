

export default async function (session, firebase) {
  // Can only be initiated on client side
  if (!process.client) {
    return
  }

  await import('firebase/messaging')

  if (firebase.messaging.isSupported()) {
    const messagingService = session.messaging()

    messagingService.usePublicVapidKey("BBS4gqjbnK_cr2159NmljE8QOd5PCGMGTNSX6MtUxxQeVmEieOv8SVNkuYpJQzY7M6wbtLa7FDMlbyR_EEmkJ5Y")

    return messagingService
  }
}
