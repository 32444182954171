import CartServices from '@/services/Cart'
export const state = () => ({
  cart: [],
  cartNavbar: {},
  isBuyNow: false, // type : cart & buynow
  cartEmpty: [],
  cartItemId: [],
  deleteItem: {},
  selectItem: [],
  checkoutItem: [],
  isLoading: true,
  withCart: true,
  selectedProduct: {},
  selectedVariant: '',
  store: '',
  qty: 1,
  cartAvailability: {}
})

export const mutations = {
  setCart (state, payload) {
    state.cart = payload
  },
  setCartNavbar (state, payload) {
    state.cartNavbar = payload
  },
  setIsBuyNow (state, payload) {
    state.isBuyNow = payload
  },
  setEmptyCart (state, payload) {
    state.cartEmpty = payload
  },
  setCartItemId (state, payload) {
    state.cartItemId = payload
  },
  setCartItemQuantity (state, { id, currentVal, type }) {
    const emptyArray = []
    const tempCart = state.cart
    tempCart.forEach(itemList =>
      itemList.cart_items.forEach(item => emptyArray.push(item))
    )
    Object.assign(
      emptyArray.find(item => item.id === id),
      {
        quantity: type === 'increase' ? currentVal + 1 : currentVal - 1
      }
    )
  },
  setDeleteItem (state, payload) {
    state.deleteItem = payload
  },
  setSelectedItem (state, payload) {
    let index = -1
    state.selectItem.map((item, i) => {
      if (item === payload) {
        index = i
      }
    })
    if (index >= 0) {
      state.selectItem.splice(index, 1)
    } else if (typeof payload === 'object') {
      payload.map((p) => {
        state.selectItem.push(p)
      })
    } else {
      state.selectItem.push(payload)
    }
  },
  resetSelectedItem (state) {
    state.selectItem = []
  },
  setCheckoutItem (state, payload) {
    let index = -1
    state.checkoutItem.map((item, i) => {
      if (item.product_detail_id === payload.product_detail_id) {
        index = i
      }
    })
    if (index >= 0) {
      state.checkoutItem.splice(index, 1)
    } else {
      state.checkoutItem.push(payload)
    }
  },
  setSelectedProduct (state, payload) {
    state.selectedProduct = payload
  },
  setSelectedVariant (state, payload) {
    state.selectedVariant = payload
  },
  resetItem (state, _payload) {
    state.checkoutItem = []
    state.selectItem = []
  },
  setWithCart (state, payload) {
    state.withCart = payload
  },
  setSelectedStore (state, payload) {
    state.store = payload
  },
  setLoading (state, payload) {
    state.isLoading = payload
  },
  setQty (state, payload) {
    state.qty = payload
  },
  setCartAvailability (state, payload) {
    state.cartAvailability = payload
  }
}

export const actions = {
  getCarts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCarts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setCart', response.data.data.carts)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getCartsNavbar ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCartsNavbar(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            const payload = {
              carts: response.data.data.carts,
              totalCart: response.data.data.total_cart
            }
            commit('setCartNavbar', payload)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getEmptyCarts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCarts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setEmptyCart', response.data.data.carts)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  addCart ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.addCart(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  addBulkCart ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.addBulkCart(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  updateCart ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.updateCart(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteCart ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.deleteCart(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getCartAvailability ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCartAvailability(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setCartAvailability', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  selectedItem ({ commit }, payload) {
    commit('setSelectedItem', payload)
  },
  checkoutItem ({ commit }, payload) {
    commit('setCheckoutItem', payload)
  },
  actionWithCart ({ commit }, payload) {
    commit('setWithCart', payload)
  },
  handlingLoading ({ commit }, payload) {
    commit('setLoading', payload)
  }
}

export const getters = {
  getTotalProductCart: (state) => {
    let total = 0
    if (!state.cart.length) {
      return 0
    }
    state.cart.map((c) => {
      total += c.cart_items.length
    })
    return total
  },
  getTotalProductCartEmpty: (state) => {
    let total = 0
    if (!state.cartEmpty.length) {
      return 0
    }
    state.cartEmpty.map((c) => {
      total += c.cart_items.length
    })
    return total
  },
  getTotalPrice (state) {
    let total = 0

    if (state.selectItem.length) {
      state.cart.map((c) => {
        if (c.cart_items) {
          c.cart_items.map((c2) => {
            state.selectItem.map((i) => {
              if (c2.id === i) {
                total += c2.product.discounted_price * c2.quantity
              }
            })
          })
        } else {
          state.selectItem.map((i) => {
            if (c.id === i) {
              total += c.product.discounted_price * c.quantity
            }
          })
        }
      })
    }

    return total
  },
  getTotalSelectedItem (state) {
    // let total = 0
    let qty = 0
    if (state.selectItem) {
      // total += state.selectItem.length
      state.selectItem.map((id) => {
        state.cart.map((store) => {
          store.cart_items.map((cart) => {
            if (cart.id === id) {
              qty += cart.quantity
            }
          })
        })
      })
    }
    return qty
  },
  getCartProductListId (state) {
    const onCartProductId = []
    state.cart.map((c) => {
      c.cart_items.map((c2) => {
        onCartProductId.push(c2.product.id)
      })
    })
    state.cartEmpty.map((c3) => {
      c3.cart_items.map((c4) => {
        onCartProductId.push(c4.product.id)
      })
    })
    return onCartProductId
  },
  getTotalPayment (state) {
    let total = 0
    state.cart.map((c) => {
      total += c.total_price - c.total_discount
    })
    return total
  },
  // navbar
  getListCart (state) {
    let carts = []

    if (state.cartNavbar.carts && state.cartNavbar.carts.length) {
      carts = [
        ...state.cartNavbar.carts
      ]
    }

    return carts
  },
  getTotalQtyCart (state) {
    let totalQty = 0

    if (state.cartNavbar.totalCart) {
      totalQty = state.cartNavbar.totalCart
      if (totalQty > 99) {
        return '99+'
      } else {
        return totalQty.toString()
      }
    }

    return totalQty.toString()
  },
  getTotalPriceCartNavbar (state) {
    let price = 0

    if (state.cartNavbar.carts) {
      const carts = state.cartNavbar.carts
      carts.map((c) => {
        price += Number(c.discounted_price) * c.quantity
      })
    }
    return price
  },
  haveCart (state) {
    return state.cart.length || state.cartEmpty.length
  },
  isEventSale (state) {
    let valid = 0
    if (state.selectItem.length) {
      state.cart.map((c) => {
        c.cart_items.map((item) => {
          const index = state.selectItem.findIndex((select) => {
            return select === item.id
          })
          if (item.product.event_ongoing.event_sale.length && index >= 0) {
            valid++
          }
        })
      })
    }
    return valid === state.selectItem.length
  },
  getPaymentDetail (state) {
    if (state.cartAvailability && Object.keys(state.cartAvailability).length) {
      return state.cartAvailability.payment_detail
    }
    return {}
  }
}
