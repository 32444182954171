export default function ({ $auth, redirect, route }) {
  if (!$auth.loggedIn) {
    let redirectFrom = ''
    if (route.name !== 'register' && route.name !== 'login' && route.name !== 'password-reset-token') {
      const params = route.fullPath || ''
      redirectFrom = `?redirect_from=${params}`
    }
    return redirect(`/login${redirectFrom}`)
  }
}
