import { urlHost, urlPath, urlUserDashboard } from '@/config'

export const state = () => ({
  urlHost,
  urlPath,
  urlUserDashboard,
  errors: [],
  isError: false,
  uploadProduct: {
    params: {},
    headers: {
      Authorization: process.client
        ? 'Bearer ' + localStorage.getItem('access-token')
        : ''
    },
    show: []
  },
  dataBreadCrumbs: [],
  csPhone: '6282111308195'
})

export const mutations = {
  SET_ERRORS (state, payload) {
    state.errors = payload
  },
  CLEAR_ERRORS (state) {
    state.errors = []
  },
  SET_ERROR (state, payload) {
    state.isError = payload
  },
  setBreadCrumbs (state, payload) {
    state.dataBreadCrumbs = payload
  }
}

export const actions = {
  handleSetByPass ({ commit }, payload) {
    commit('setByPass', payload)
  }
}

export const getters = {}
