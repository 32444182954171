import AffiliateServices from '@/services/Affiliate'
import { getPageNumber } from '@/utils/helper'

export const state = () => ({
  summaryCount: {},
  commissionHistory: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  },
  withdrawables: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  },
  selectedWithdrawables: [],
  commissionSummaryCount: {},
  detailHistory: {
    data: {},
    loading: false
  },
  affiliateWithdrawals: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  },
  detailAffiliateWithdrawal: {
    data: {},
    loading: false
  }
})

export const mutations = {
  setLoading (state, payload) {
    const { path, type, value, loadMore } = payload
    function index (obj, i) {
      return obj[i];
    }

    if (loadMore) {
      path.split('.').reduce(index, state).loadMore = value;
    } else {
      path.split('.').reduce(index, state)[type] = value;
    }
  },
  setSummaryCount (state, payload) {
    state.summaryCount = payload
  },
  setCommissionHistory (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.commissionHistory.data.length) {
        state.commissionHistory.data = data
      } else {
        const oldData = state.commissionHistory.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.commissionHistory.data = oldData.concat(data)
      }

      state.commissionHistory.pagination = { totalPage, total, nextPage }
      state.commissionHistory.paginated = paginated
    }
  },
  resetCommissionHistory (state, payload) {
    state.commissionHistory = {
      data: [],
      pagination: { totalPage: 0, total: 0 },
      paginated: true,
      loadMore: false,
      loading: false
    }
  },
  setWithdrawables (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.withdrawables.data.length) {
        state.withdrawables.data = data
      } else {
        const oldData = state.withdrawables.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.withdrawables.data = oldData.concat(data)
      }

      state.withdrawables.pagination = { totalPage, total, nextPage }
      state.withdrawables.paginated = paginated
    } else {
      state.withdrawables.data = data
      state.withdrawables.pagination = { totalPage, total, nextPage }
      state.withdrawables.paginated = paginated
    }
  },
  resetWithdrawables (state, payload) {
    state.withdrawables = {
      data: [],
      pagination: { totalPage: 0, total: 0 },
      paginated: true,
      loadMore: false
    }
  },
  setSelectedWithdrawables (state, payload) {
    state.selectedWithdrawables = payload
  },
  setCommissionSummaryCount (state, payload) {
    state.commissionSummaryCount = payload
  },
  setDetailHistory (state, payload) {
    state.detailHistory.data = payload
  },
  setAffiliateWithdrawals (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.affiliateWithdrawals.data.length) {
        state.affiliateWithdrawals.data = data
      } else {
        const oldData = state.affiliateWithdrawals.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.affiliateWithdrawals.data = oldData.concat(data)
      }
    } else {
      state.affiliateWithdrawals.data = data
    }

    state.affiliateWithdrawals.pagination = { totalPage, total, nextPage }
    state.affiliateWithdrawals.paginated = paginated
  },
  resetAffiliateWithdrawals (state, payload) {
    state.affiliateWithdrawals = {
      data: [],
      pagination: { totalPage: 0, total: 0 },
      paginated: true,
      loadMore: false,
      loading: false
    }
  },
  setDetailAffiliateWithdrawal (state, payload) {
    state.detailAffiliateWithdrawal.data = payload
  }
}

export const getters = {
  getSelectedWithdrawablesAmount (state) {
    if (state.selectedWithdrawables.length) {
      let count = 0

      state.selectedWithdrawables.forEach((selected) => {
        count += selected.amount
      })

      return count
    }

    return 0
  }
}

export const actions = {
  acceptPrivacyPolicy (auth, payload) {
    return new Promise((resolve, reject) => {
      AffiliateServices.acceptPrivacyPolicy({
        loggedIn: this.$auth.loggedIn,
        value: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : this.$cookies.get('session_id') || ''
      }, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSummaryCount ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AffiliateServices.getSummaryCount(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setSummaryCount', response.data.data.affiliates)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getCommissionHistory ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AffiliateServices.getAffiliateCommission(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const history = response.data.data.affiliate_balance_histories
          commit('setCommissionHistory', {
            paginated: payload.params.paginated,
            data: history.data,
            totalPage: history.last_page,
            total: history.total,
            nextPage: getPageNumber(history.next_page_url)
          })
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getWithdrawablesAffiliate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', {
        path: 'withdrawables',
        type: 'loading',
        value: true,
        loadMore: false
      })
      AffiliateServices.getWithdrawablesAffiliate(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const withdrawables = response.data.data.withdrawables
          const paginated = payload.data.paginated
          const withdrawPayload = {
            paginated,
            data: paginated ? withdrawables.data : withdrawables,
            totalPage: paginated ? withdrawables.last_page : 1,
            total: paginated ? withdrawables.total : withdrawables.length,
            nextPage: paginated ? getPageNumber(withdrawables.next_page_url) : null
          }
          commit('setWithdrawables', withdrawPayload)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', {
            path: 'withdrawables',
            type: 'loading',
            value: false,
            loadMore: false
          })
        })
    })
  },
  createWithdrawalAffiliate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AffiliateServices.createWithdrawalAffiliate({
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getWithdrawalCheckAmount (auth, payload) {
    return new Promise((resolve, reject) => {
      AffiliateServices.getWithdrawalCheckAmount({
        loggedIn: this.$auth.loggedIn,
        value: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : this.$cookies.get('session_id') || ''
      }, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCommissionSummaryCount ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AffiliateServices.getCommissionSummaryCount(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setCommissionSummaryCount', response.data.data.commission_summary)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getCommissionHistoryDetail ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', {
        path: 'detailHistory',
        type: 'loading',
        value: true,
        loadMore: false
      })
      AffiliateServices.getCommissionHistoryDetail(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setDetailHistory', response.data.data.affiliate_balance_history)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', {
            path: 'detailHistory',
            type: 'loading',
            value: false,
            loadMore: false
          })
        })
    })
  },
  getAffiliateBalanceWithdrawal ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', {
        path: 'affiliateWithdrawals',
        type: 'loading',
        value: true,
        loadMore: false
      })
      AffiliateServices.getAffiliateBalanceWithdrawal(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const affiliateWithdrawal = response.data.data.affiliate_balance_withdrawals
          const paginated = payload.params.paginated
          const affiliateWithdrawalPayload = {
            paginated,
            data: paginated ? affiliateWithdrawal.data : affiliateWithdrawal,
            totalPage: paginated ? affiliateWithdrawal.last_page : 1,
            total: paginated ? affiliateWithdrawal.total : affiliateWithdrawal.length,
            nextPage: paginated ? getPageNumber(affiliateWithdrawal.next_page_url) : null
          }
          commit('setAffiliateWithdrawals', affiliateWithdrawalPayload)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', {
            path: 'affiliateWithdrawals',
            type: 'loading',
            value: false,
            loadMore: false
          })
        })
    })
  },
  getAffiliateBalanceWithdrawalDetail ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', {
        path: 'detailAffiliateWithdrawal',
        type: 'loading',
        value: true,
        loadMore: false
      })
      AffiliateServices.getAffiliateBalanceWithdrawalDetail(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setDetailAffiliateWithdrawal', response.data.data.affiliate_balance_withdrawal)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', {
            path: 'detailAffiliateWithdrawal',
            type: 'loading',
            value: false,
            loadMore: false
          })
        })
    })
  },
  increaseClickAffiliateProduct ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AffiliateServices.increaseClickAffiliateProduct(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createAffiliateRegistration ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      AffiliateServices.createAffiliateRegistration(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
