const middleware = {}

middleware['affiliate'] = require('../middleware/affiliate.js')
middleware['affiliate'] = middleware['affiliate'].default || middleware['affiliate']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['user'] = require('../middleware/user.js')
middleware['user'] = middleware['user'].default || middleware['user']

export default middleware
